"use strict";

/* eslint-disable */
/*globals  PULSE.app, PULSE.core */

(function (app, core) {
  "use strict";

  app.webView = function () {
    const webview = core.url.getParam("webview");
    const webviewCookie = core.url.getParam("webview_cookie");
    if (webviewCookie && webviewCookie === 'true') {
      core.localStorage.setStorage('webviewCookie', true, 100, true);
    }
    const webviewFromStorage = core.localStorage.getStorage('webviewCookie', true);
    if (webview && webview === 'true' || webviewFromStorage && webviewFromStorage !== '') {
      core.style.addClass(document.body, 'web-view-body');
    } else {
      const webView404 = document.querySelector('.js-webview-404');
      if (webView404) {
        core.style.removeClass(webView404, 'u-hide');
      }
    }
  };
})(PULSE.app, PULSE.core);
/* eslint-enable */