"use strict";

(function (app) {
  'use strict';

  /**
   * Map of media query size labels to screen widths
   */
  app.measurements = {
    mobile: 400,
    phablet: 640,
    tablet: 840,
    desktop: 1025,
    wide: 1300
  };
})(PULSE.app);