"use strict";

/* eslint-disable id-length */

(function (app) {
  'use strict';

  /**
   * @typedef {object} OrderDefinition
   * @property {string} direction
   * @property {string} parameterName
   * @property {number} viewCountTimeWindowSeconds
   */

  /**
   * @typedef {object} ReferenceDefinition
   * @property {string} contentType
   * @property {number} id
   * @property {string} reference
   */

  /**
   * @typedef {object} TagsDefinition
   * @property {number} accountId
   * @property {Array.<TagDefinition>} tags
   */

  /**
   * @typedef {object} TagDefinition
   * @property {number} id
   * @property {string} label
   */

  /**
   * @typedef {object} SearchQuery Search query object that is passed from content query widgets
   * @property {number} accountId
   * @property {Array.<string>} contentTypes
   * @property {string} language
   * @property {string} level
   * @property {boolean} onlyRestrictedContent
   * @property {Array.<OrderDefinition>} order
   * @property {PaginationDefinition} pagination
   * @property {number} publishedAfter
   * @property {number} publishedBefore
   * @property {Array.<ReferenceDefinition>} references
   * @property {TagsDefinition} tags
   */

  /**
   * @typedef {object} FootballFilters Search query object that is passed from football widgets
   * @property {number} accountId
   * @property {number} formLength
   * @property {Array.<number>} compSeasons
   * @property {Array.<number>} comps
   * @property {Array.<number>} gameweeks
   * @property {Array.<number>} teams
   * @property {boolean} live
   * @property {boolean} provisional
   * @property {boolean} versus
   * @property {boolean} altIds
   * @property {string} language
   */

  /**
   * @typedef {object} CricketFilters Search query object that is passed from cricket widgets
   * @property {number} minimumTeams
   * @property {Array.<string>} matchStates
   * @property {Array.<string>} matchTypes
   * @property {Array.<string>} teamTypes
   * @property {Array.<string>} tournamentTypes
   * @property {boolean} active
   * @property {boolean} altIds
   * @property {boolean} entryPerDay
   * @property {boolean} versus
   * @property {string} endDate
   */

  /**
   * @typedef {object} PulseFilterConfig config object used to construct a single filter instance
   * @property {FilterConfig} fylterConfig the config passed to the fylter library see the fylter repo for more infor
   * @property {Function} [getDefaultFromSearchParams] when passed a search query object (cricketFilters, footballFilters, searchQuery) return the id of a default option will override fylterConfig.middleWare.decideDefault
   * @property {Function} [getOptionsFromSearchParams] when passed a search query object return a list of Option objects. See fylter repo for option typedef will override fylterConfig.data
   */

  /**
   * @typedef {Array.<PulseFilterConfig>} FilterGroup
   */

  /**
   * @typedef {object.<string, FilterGroup>} FilterGroupsMapping
   */

  /**
   * @type {FilterGroupsMapping}
   */
  app.FilterGroups = {};
  const getTagString = tags => tags.map(tag => tag.label).join(',');
  app.FilterGroups.CONTENT = [{
    fylterConfig: {
      name: 'Category'
    },
    getDefaultFromSearchParams: searchQuery => {
      try {
        return getTagString(searchQuery.tags.tags);
      } catch (error) {
        console.warn('ContentFilter getOptionsFromSearchParams, could not find tags on searchQuery', error);
      }
      return -1;
    },
    getOptionsFromSearchParams: searchQuery => {
      /**
       * this provides an example of specifying a default list of items and adding to that
       * list with information from the CMS Search Query when it does not exist in the list
       */
      let searchQueryOption = '';
      try {
        searchQueryOption = getTagString(searchQuery.tags.tags);
      } catch (error) {
        console.warn('ContentFilter getOptionsFromSearchParams, could not find tags on searchQuery', error);
      }
      const defaultOptions = [{
        name: 'All',
        value: -1
      }, {
        name: 'First Team News',
        value: 'news,first team'
      }];
      const hasTag = defaultOptions.filter(tagObj => tagObj.value === searchQueryOption).length;
      const returnDefault = hasTag || !searchQueryOption;
      if (returnDefault) {
        return defaultOptions;
      }
      const optionsWithNewElement = [...defaultOptions, {
        name: searchQueryOption,
        value: searchQueryOption
      }];
      return optionsWithNewElement;
    }
  }, {
    fylterConfig: {
      name: 'Competition',
      data: () => [{
        name: 'All',
        value: -1
      }, {
        name: 'Premier League',
        value: 'FOOTBALL_COMPETITION:1'
      }, {
        name: 'Champions League',
        value: 'FOOTBALL_COMPETITION:2'
      }]
    },
    getDefaultFromSearchParams: searchQuery => {
      if (!searchQuery.references || !searchQuery.references.length) {
        return -1;
      }
      return `${searchQuery.references[0].contentType}:${searchQuery.references[0].reference}`;
    }
  }];
  app.FilterGroups.CRICKET = [{
    fylterConfig: {
      name: 'Date'
    },
    getOptionsFromSearchParams: cricketFilters => {
      const year = new Date(cricketFilters.endDate).getFullYear();
      /* eslint-disable-next-line no-magic-numbers */
      const options = [year, year - 1, year - 2, year - 3].map(yearVal => ({
        name: yearVal,
        value: `01-01-${yearVal}`
      }));
      return [{
        name: 'All',
        value: -1
      }, ...options];
    },
    getDefaultFromSearchParams: cricketFilters => {
      const year = new Date(cricketFilters.endDate).getFullYear();
      return `01-01-${year}`;
    }
  }, {
    fylterConfig: {
      name: 'Match Types',
      data: () => {
        return [{
          name: 'All',
          value: 'ALL'
        }, {
          name: 'Test',
          value: 'TEST'
        }, {
          name: 'ODI',
          value: 'ODI'
        }, {
          name: 'T20I',
          value: 'T20I'
        }, {
          name: 'IPLT20',
          value: 'IPLT20'
        }, {
          name: 'CLT20',
          value: 'CLT20'
        }, {
          name: 'T20',
          value: 'T20'
        }, {
          name: 'List A',
          value: 'LIST_A'
        }, {
          name: 'First Class',
          value: 'FIRST_CLASS'
        }, {
          name: 'World Cup',
          value: 'CWC'
        }, {
          name: 'Other',
          value: 'OTHER'
        }];
      }
    },
    getDefaultFromSearchParams: cricketFilters => cricketFilters.matchTypes[0]
  }, {
    fylterConfig: {
      name: 'Team Types',
      data: () => {
        return [{
          name: 'Mens',
          value: 'm'
        }, {
          name: 'Womens',
          value: 'w'
        }, {
          name: 'Men Youth',
          value: 'my'
        }];
      }
    },
    getDefaultFromSearchParams: cricketFilters => cricketFilters.teamTypes[0]
  }];
  app.FilterGroups.FOOTBALL = [{
    fylterConfig: {
      name: 'Competition',
      data: () => {
        return '//api.dev.platform.pulselive.com/football/competitions?page=0&pageSize=20&detail=1';
      },
      middleWares: {
        beforeOptionUpdate: data => {
          const options = data.data.content.map(comp => {
            return {
              name: comp.description,
              value: comp.id,
              extra: comp
            };
          });
          return [{
            name: 'All Comps',
            value: '-1'
          }].concat(options);
        }
      }
    },
    getDefaultFromSearchParams: footballFilters => footballFilters.comps[0]
  }, {
    fylterConfig: {
      name: 'CompSeason',
      dependsOn: ['Competition'],
      data: () => {
        return '//api.dev.platform.pulselive.com/football/competitions/{{Competition}}/compseasons?page=0&pageSize=100';
      },
      middleWares: {
        beforeOptionUpdate: data => {
          const options = data.data.content.map(season => {
            return {
              name: season.label,
              value: season.id
            };
          });
          return [{
            name: 'All CompSeasons',
            value: '-1'
          }].concat(options);
        },
        decideFilterEnabledState: deps => {
          return deps[0].getCurrentOption().value !== '-1';
        }
      }
    },
    getDefaultFromSearchParams: footballFilters => footballFilters.compSeasons[0]
  }, {
    fylterConfig: {
      name: 'GameWeeks',
      dependsOn: ['CompSeason'],
      data: () => {
        return '//api.dev.platform.pulselive.com/football/compseasons/{{CompSeason}}/gameweeks';
      },
      middleWares: {
        beforeOptionUpdate: data => {
          const options = data.data.gameweeks.map(gweek => {
            return {
              name: gweek.gameweek,
              value: gweek.id,
              extra: gweek
            };
          });
          return [{
            name: 'All Gameweeks',
            value: '-1'
          }].concat(options);
        },
        decideFilterEnabledState: deps => {
          return deps[0].getCurrentOption().value !== '-1';
        }
      }
    },
    getDefaultFromSearchParams: footballFilters => footballFilters.gameweeks[0]
  }, {
    fylterConfig: {
      name: 'HomeAway',
      data: () => {
        return [{
          name: 'Home',
          value: 'home'
        }, {
          name: 'Away',
          value: 'away'
        }];
      }
    }
  }];
  app.FilterGroups.FootballFixturesList = [{
    fylterConfig: {
      name: 'selectteam',
      middleWares: {
        beforeOptionUpdate: _ref => {
          let {
            data
          } = _ref;
          return data.teams.map(team => ({
            name: PULSE.I18N.lookup(`label.team.type.${team.teamType}`),
            value: team.id
          }));
        }
      }
    },
    getDefaultFromSearchParams: footballQuery => footballQuery.footballFilters.teams[0],
    getOptionsFromSearchParams: footballQuery => {
      const club = footballQuery.footballClubList[0];
      return `${app.environment.api}/football/clubs/${club.id}?language=${app.language}`;
    }
  }, {
    fylterConfig: {
      name: 'selectcompetition',
      dependsOn: ['selectteam'],
      data: () => `${app.environment.api}/football/competitions/?teams={{selectteam}}&pageSize=20`,
      middleWares: {
        beforeOptionUpdate: _ref2 => {
          let {
            data
          } = _ref2;
          const comps = data.content.map(comp => ({
            name: comp.description,
            value: comp.id
          }));
          return [{
            name: PULSE.I18N.lookup('label.all'),
            value: -1
          }, ...comps];
        }
      }
    },
    getDefaultFromSearchParams: footballQuery => footballQuery.footballFilters.comps[0]
  }, {
    fylterConfig: {
      name: 'selectseason',
      dependsOn: ['selectcompetition'],
      data: () => `${app.environment.api}/football/competitions/{{selectcompetition}}/compseasons?pageSize=100`,
      middleWares: {
        beforeOptionUpdate: _ref3 => {
          let {
            data
          } = _ref3;
          return data.content.map(season => ({
            name: season.label,
            value: season.id
          }));
        },
        decideFilterEnabledState: parentFilters => parentFilters[0].getCurrentId().toString() !== '-1'
      }
    },
    getDefaultFromSearchParams: footballQuery => footballQuery.footballFilters.compSeasons[0]
  }];
})(PULSE.app);