"use strict";

(function (app) {
  'use strict';

  /**
   * Map of media query size labels to screen widths
   */
  app.breakpoints = {
    mobile: 400,
    mobileMin: 401,
    mobileLg: 500,
    mobileLgMin: 501,
    phablet: 640,
    phabletMin: 641,
    tablet: 840,
    tabletMin: 841,
    desktop: 1024,
    desktopMin: 1025,
    wide: 1300,
    wideMin: 1301
  };
})(PULSE.app);