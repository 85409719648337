"use strict";

/* globals PULSE.core */ // eslint-disable-line
/* eslint id-length: ["error", { "exceptions": ["LT", "LTS", "l", "L", "ll",
 * "LL", "lll", "LLL", "llll", "LLLL" ] }] */
/* eslint no-console: ["error", { allow: ["warn"] }] */

(function (app, core) {
  'use strict';

  app.I18N = {};
  app.I18N.setup = () => {
    const reqLanguage = core.localStorage.getStorage('req_language', true);
    app.language = reqLanguage || app.defaultLanguage;
    const Translator = new app.I18N.Translator(PULSE.I18N);
    if (!PULSE.I18N) {
      PULSE.I18N = {};
    }
    PULSE.I18N.lookup = function lookup() {
      return Translator.lookup.apply(Translator, arguments);
    };
    PULSE.I18N.lookupKey = function lookupKey() {
      return Translator.lookupKey.apply(Translator, arguments);
    };
    if (typeof window.moment !== 'undefined') {
      app.I18N.enOverride();
      moment.locale(app.language);
    }
  };
  app.I18N.enOverride = () => {
    moment.locale('en', {
      longDateFormat: {
        LT: 'HH:mm',
        // LT: "h:mm A",
        LTS: 'h:mm:ss A',
        l: 'DD/MM',
        L: 'DD/MM/YYYY',
        ll: 'D MMMM',
        LL: 'D MMMM YYYY',
        lll: 'MMM D YYYY LT',
        LLL: 'MMMM Do YYYY LT',
        llll: 'ddd MMM D YYYY',
        LLLL: 'dddd, MMMM Do YYYY'
      },
      yearFirst: false
    });
  };
  app.I18N.Translator = function Translator(translationsData) {
    this.hasTranslations = false;
    this.language = app.language;
    if (translationsData) {
      this.hasTranslations = true;
      if (this.language !== translationsData.language) {
        if (typeof translationsData.language === 'undefined') {
          console.warn('Language mismatch! Using ' + translationsData.language);
        }
        this.language = translationsData.language;
      }
      this.translations = translationsData.translations || {};
      this.hasTranslations = true;
    } else {
      this.hasTranslations = false;
      this.translations = {};
    }
  };
  app.I18N.Translator.prototype.lookup = function lookup(key) {
    let replaceMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (key && replaceMap) {
      let mapping = this.lookupKey(key);
      for (let replaceKey in replaceMap) {
        if ({}.hasOwnProperty.call(replaceMap, replaceKey)) {
          let regExp = new RegExp('\\${' + replaceKey + '}', 'gi');
          mapping = mapping.replace(regExp, replaceMap[replaceKey]);
        }
      }
      return mapping;
    }
    return '';
  };
  app.I18N.Translator.prototype.lookupKey = function lookupKey(key) {
    if (this.hasTranslations) {
      return this.translations[key] || key;
    }
    return key;
  };
})(PULSE.app, PULSE.core);