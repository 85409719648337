"use strict";

/*eslint no-console: ["error", { allow: ["warn", "error", "info"] }] */
(function (app) {
  'use strict';

  app.templating = {};
  app.templating.helper = {};

  /**
   * Renders a template with the given data and returns the compiled template
   *
   * @param {object}  model             - data to render in JSON format
   * @param {string}  templateName      - the name of the template (must match file name)
   * @param {boolean} parseToHtmlObject - parse the rendered template string to an HTML object - default false
   * @returns {(string | DOMElement)}      - Rendered template with model
   */
  app.templating.render = function () {
    let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let templateName = arguments.length > 1 ? arguments[1] : undefined;
    let parseToHtmlObject = arguments.length > 2 ? arguments[2] : undefined;
    let renderedTemplate = '';
    for (let func in app.templating) {
      if (app.templating.hasOwnProperty(func)) {
        model[func] = app.templating[func];
      }
    }

    // attach app env to the main underscore template
    model.environment = app.environment;
    model.helper.date = app.common.date;
    model.helper.template = app.common.template;
    model.helper.image = app.common.image;
    if (templateName) {
      let templateEngine = app.templates[templateName];
      if (templateEngine) {
        try {
          renderedTemplate = templateEngine(model);
        } catch (error) {
          if (error.message) {
            error.message += ' in template ' + templateName;
          }
          console.warn(error);
          console.info('Model given', model);
        }
        if (parseToHtmlObject) {
          return stringToElement(renderedTemplate);
        }
      } else {
        console.warn('No template was rendered. Template not found: ' + templateName);
      }
    }
    return renderedTemplate;
  };

  /**
   * Parses an HTML string to an actual element
   *
   * @param {string} htmlString - HTML string you want to parse to an HTML element
   * @returns {DOMElement} - HTMLstring cast to a DOMElement
   */
  const stringToElement = htmlString => {
    let element = document.createElement('div');
    element.innerHTML = htmlString.trim();
    return element.firstChild;
  };
})(PULSE.app);