"use strict";

(function (app) {
  'use strict';

  app.account = 'worldrugby';
  app.templates = {};
  app.common = {};
  app.defaultLanguage = 'EN';
  app.widgets = {};
  app.paths = [{
    "api": "//api.wr.rims-dev.pulselive.com",
    "cdn": "//svns.wr.dev.pulselive.com/resources/{$SVNS_RES_VER$}/",
    "contentRanking": "https://1s73fflkpi.execute-api.us-east-1.amazonaws.com/LATEST/interactions",
    "crmApi": "//crmapidev.pulselive.com",
    "predictorApi": "//dev-wr-predictor.pulselive.com/api/predictor",
    "domain": ["svns.wr.dev.pulselive.com"],
    "form": "//form-api.dev.worldrugby.org/worldrugby/forms/",
    "label": "dev",
    "siteId": 37,
    "sso": {
      "auth": "https://sso.wr.dev.pulselive.com/auth",
      "account": "https://sso.wr.dev.pulselive.com/auth/realms/WorldRugby/account",
      "id": "wr-websites-dev",
      "realm": "WorldRugby"
    }
  }, {
    "api": "//api.wr-rims-prod.pulselive.com",
    "cdn": "//www.svns.com/resources/{$SVNS_RES_VER$}/",
    "contentRanking": "https://1s73fflkpi.execute-api.us-east-1.amazonaws.com/LATEST/interactions",
    "crmApi": "//crmapi.pulselive.com",
    "predictorApi": "//wr-predictor.pulselive.com/api/predictor",
    "domain": ["www.svns.com", "www.svnsseries.com", "localhost"],
    "form": "//form-api.worldrugby.org/worldrugby/forms/",
    "label": "production",
    "siteId": 29,
    "sso": {
      "auth": "https://sso.world.rugby/auth",
      "account": "https://sso.world.rugby/auth/realms/WorldRugby/account",
      "id": "wr-websites",
      "realm": "WorldRugby"
    }
  }, {
    "api": "//api.wr.staging.pulselive.com",
    "cdn": "//svns.wr.test.pulselive.com/resources/{$SVNS_RES_VER$}/",
    "contentRanking": "https://1s73fflkpi.execute-api.us-east-1.amazonaws.com/LATEST/interactions",
    "crmApi": "//crmapitest.pulselive.com",
    "predictorApi": "//test-wr-predictor.pulselive.com/api/predictor",
    "domain": ["svns.wr.test.pulselive.com", "localhost"],
    "form": "//form-api.test.worldrugby.org/worldrugby/forms/",
    "label": "test",
    "siteId": 30,
    "sso": {
      "auth": "https://sso.wr.test.pulselive.com/auth",
      "account": "https://sso.wr.test.pulselive.com/auth/realms/WorldRugby/account",
      "id": "wr-websites-test",
      "realm": "WorldRugby"
    }
  }].map(path => {
    path.cdn = path.cdn.replace('{$SVNS_RES_VER$}', window.SVNS_RES_VER);
    return path;
  });
  app.checkEnvironment = () => {
    const location = window.location.hostname;
    let environment;
    for (let i = 0; i < app.paths.length; i++) {
      const path = app.paths[i];
      if (typeof browsersyncProxy !== 'undefined' && (location === 'localhost' || /^(?=.*[^\.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.?){4}$/gim.test(location))) {
        // eslint-disable-line no-useless-escape

        browsersyncProxy = browsersyncProxy.replace('https://', ''); // eslint-disable-line no-undef
        const envIndex = app.paths.findIndex(appPath => {
          // eslint-disable-line no-loop-func
          return appPath.domain.indexOf(browsersyncProxy) > -1; // eslint-disable-line no-undef
        });
        environment = app.paths[envIndex];
        environment.cdn = '/';
        break;
      } else if (Array.isArray(path.domain)) {
        const foundDomain = _checkDomains(path.domain, location);
        if (foundDomain) {
          path.domain = foundDomain;
          environment = path;
          break;
        }
      } else if (location === path.domain || location.match(new RegExp(path.domain))) {
        environment = path;
        break;
      }
    }
    if (!environment) {
      console.error('There are no app.paths defined for this domain');
    }
    return environment;
  };

  /**
   * Check Domains
   *
   * Given an array of domains and location hostame it will check if any match
   *
   * @param {Array} domains - Array of domains from a paths blob
   * @param {string} location - window.location.hostname
   * @returns {?string} - The found domain or null
   */
  const _checkDomains = (domains, location) => {
    for (let j = 0; j < domains.length; j++) {
      const domain = domains[j];
      if (location === domain || location.match(new RegExp(domain))) {
        return domain;
      }
    }
    return null;
  };
  app.environment = app.checkEnvironment();
  const DOMReady = () => {
    app.I18N.setup();
  };
  document.addEventListener('DOMContentLoaded', DOMReady);
})(PULSE.app);