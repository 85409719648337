"use strict";

/**
 * Pulse Platform Endpoints
 *
 * Docs are here: http://swagger.pulselive.com/
 */
(function (app, common) {
  'use strict';

  common.endpoints = common.endpoints || {};
  const account = 'worldrugby';
  const prepareParams = common.prepareParams;
  let apiEnv;
  apiEnv = app.environment.api;

  /**
   * Rugby API
   */
  common.endpoints.rugby = {
    stats: {
      player: (playerId, scope, params) => `${apiEnv}/rugby/v3/stats/player/${playerId}/${scope}${prepareParams(params)}`,
      teamHeadToHead: (teamIdA, teamIdB, params) => `${apiEnv}/rugby/v3/stats/team/${teamIdA}/hth/${teamIdB}${prepareParams(params)}`,
      team: (teamId, scope, params) => `${apiEnv}/rugby/stats/v3/team/${teamId}/${scope}${prepareParams(params)}`,
      topTeamPlayers: (teamId, scope, metric, params) => `${apiEnv}/rugby/v3/stats/topplayers/team/${teamId}/${scope}/${metric}${prepareParams(params)}`,
      topPlayers: (scope, metric, params) => `${apiEnv}/rugby/stats/v3/topplayers/${scope}/${metric}${prepareParams(params)}`,
      topTeams: (scope, metric, params) => `${apiEnv}/rugby/stats/v3/topteams/${scope}/${metric}${prepareParams(params)}`
    },
    team: {
      all: params => `${apiEnv}/rugby/v3/team${prepareParams(params)}`,
      byId: (teamId, params) => `${apiEnv}/rugby/v3/team/${teamId}${prepareParams(params)}`,
      history: (teamId, eventId, params) => `${apiEnv}/rugby/v3/team/${teamId}/history/${eventId}${prepareParams(params)}`,
      players: (teamId, params) => `${apiEnv}/rugby/v3/team/${teamId}/players${prepareParams(params)}`,
      ranking: (teamId, params) => `${apiEnv}/rugby/v3/team/${teamId}/ranking${prepareParams(params)}`
    },
    series: {
      all: params => `${apiEnv}/rugby/v3/series${prepareParams(params)}`,
      numbers: (seriesId, params) => `${apiEnv}/rugby/v3/series/${seriesId}${prepareParams(params)}`,
      byId: (seriesId, params) => `${apiEnv}/rugby/v3/series/${seriesId}/numbers${prepareParams(params)}`,
      standings: (seriesId, params) => `${apiEnv}/rugby/v3/series/${seriesId}/standings${prepareParams(params)}`
    },
    event: {
      all: params => `${apiEnv}/rugby/v3/event${prepareParams(params)}`,
      byId: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}${prepareParams(params)}`,
      eventGroup: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/eventgroup${prepareParams(params)}`,
      numbers: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/numbers${prepareParams(params)}`,
      player: (eventId, playerId, params) => `${apiEnv}/rugby/v3/event/${eventId}/player/${playerId}${prepareParams(params)}`,
      schedule: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/schedule${prepareParams(params)}`,
      series: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/series${prepareParams(params)}`,
      squad: (eventId, teamId, params) => `${apiEnv}/rugby/v3/event/${eventId}/squad/${teamId}${prepareParams(params)}`,
      squads: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/squads${prepareParams(params)}`,
      standings: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/standings${prepareParams(params)}`,
      teams: (eventId, params) => `${apiEnv}/rugby/v3/event/${eventId}/teams${prepareParams(params)}`,
      squadPlayer: (eventId, teamId, playerId) => `${apiEnv}/rugby/v3/event/${eventId}/squad/${teamId}/player/${playerId}`
    },
    match: {
      all: params => `${apiEnv}/rugby/v3/match/${prepareParams(params)}`,
      byId: (matchId, params) => `${apiEnv}/rugby/v3/match/${matchId}${prepareParams(params)}`,
      stats: (matchId, params) => `${apiEnv}/rugby/v3/match/${matchId}/stats${prepareParams(params)}`,
      summary: (matchId, params) => `${apiEnv}/rugby/v3/match/${matchId}/summary${prepareParams(params)}`,
      timeline: (matchId, params) => `${apiEnv}/rugby/v3/match/${matchId}/timeline${prepareParams(params)}`
    },
    venues: {
      all: params => `${apiEnv}/rugby/v3/venue${prepareParams(params)}`,
      byId: (venueId, params) => `${apiEnv}/rugby/v3/venue/${venueId}${prepareParams(params)}`
    },
    broadcasting: {
      byEvent: params => `${apiEnv}/rugby/broadcasting-schedule/events${prepareParams(params)}`,
      byMatch: params => `${apiEnv}/rugby/broadcasting-schedule/matches${prepareParams(params)}`
    }
  };

  /**
   * Content API
   */
  common.endpoints.content = {
    byLangId: (lang, id, params) => `${apiEnv}/content/${account}/all/${lang}/${id}${prepareParams(params)}`,
    related: (lang, params) => `${apiEnv}/content/${account}/getRelatedContent/${lang}${prepareParams(params)}`,
    videosForDuration: (lang, params) => `${apiEnv}/content/${account}/getVideosForDuration/${lang}${prepareParams(params)}`,
    multiType: (lang, params) => `${apiEnv}/content/${account}/${lang}${prepareParams(params)}`,
    typedContent: (type, lang, params) => `${apiEnv}/content/${account}/${type}/${lang}${prepareParams(params)}`,
    byTypeLangId: (type, lang, id, params) => `${apiEnv}/content/${account}/${type}/${lang}/${id}${prepareParams(params)}`
  };

  /**
   * Live Blog
   */
  common.endpoints.liveBlog = {
    getBlog: (id, lang, params) => `${apiEnv}/liveblog/${account}/${id}/${lang}${prepareParams(params)}`,
    getBlogAfterPosition: (id, lang, position, params) => `${apiEnv}/liveblog/${account}/${id}/${lang}/above/${position}${prepareParams(params)}`,
    getBlogBeforePosition: (id, lang, position, params) => `${apiEnv}/liveblog/${account}/${id}/${lang}/below/${position}${prepareParams(params)}`,
    getBlogNewerThan: (id, lang, timestamp, params) => `${apiEnv}/liveblog/${account}/${id}/${lang}/newerThan/${timestamp}${prepareParams(params)}`,
    getBlogSummary: (lang, params) => `${apiEnv}/liveblog/${account}/${lang}${prepareParams(params)}`
  };
  /**
   * Search Controller API
   *
   * @param params
   */
  common.endpoints.search = params => `${apiEnv}/search/${account}${prepareParams(params)}`;

  /**
   * CRM API
   */
  common.endpoints.crm = {
    eventgroup: (lang, id, params) => `${app.environment.crmApi}/${account}/eventgroup/${lang}/${id}/events${prepareParams(params)}`
  };
})(PULSE.app, PULSE.app.common);